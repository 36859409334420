import { Box, Checkbox, Flex, Label, Text } from '@asktia/tia-ui'
import { format } from 'date-fns'
import * as ThemeUI from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import { ArrayField, useFieldArray, useFormContext } from 'react-hook-form'
import { Divider } from 'src/components/Blocks'
import { PlusIcon } from 'src/components/Blocks/Icons'
import { useGetImmunizations } from '../../hooks/useGetImmunizations'
import { useAmpli } from 'src/hooks'
import { lastTouchedByPatient } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/helpers'
import { useCreateImmunizations } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/hooks/useCreateImmunizations'
import { useEditImmunization } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/hooks/useEditImmunization'
import { MedplumImmunizationItem } from 'src/flows/questionnaires/MedicalHistory/types'

export interface ImmunizationFieldProps {
    label: string
    name: string
    immunizationCode: string
    immunizationDisplay: string
    immunizationSystem: string
    defaultSelected?: boolean
    isRecommended?: boolean
    supportText?: string

    onCheckboxClick?: (
        evt: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void
}

export const ImmunizationField: FC<ImmunizationFieldProps> = ({
    label,
    name,
    immunizationCode,
    immunizationDisplay,
    immunizationSystem,
    defaultSelected,
    supportText,
    isRecommended,
    onCheckboxClick
}) => {
    const [loaded, setLoaded] = useState(false)
    const { addedImmunization, removedImmunization, editedImmunization } =
        useAmpli()
    const { immunizations, isLoading } = useGetImmunizations()
    const { mutate: createImmunization } = useCreateImmunizations()
    const { mutate: editImmunization } = useEditImmunization()
    const datesName = `${name}-dates`

    const { fields, append, remove } = useFieldArray({
        name: datesName
    })

    const { register, watch, setValue } = useFormContext()
    const isChecked = watch(name)

    const handleOnBlur = (
        evt: React.FocusEvent<HTMLInputElement, Element>,
        field: Partial<ArrayField<Record<string, any>, 'id'>>
    ) => {
        const value = new Date(evt.target.value)

        if (!(value instanceof Date) || isNaN(value.getTime())) {
            // Invalid date
            return
        }

        if (value.getUTCFullYear() <= 1900) {
            // Too old... probably wrong date... just ignore
            return
        }

        const immunization = immunizations.find(
            x => x.id === field.immunizationId
        )
        if (!immunization) {
            // CREATE
            console.log('Creating immunization', {
                immunizationCode,
                immunizationDisplay,
                immunizationSystem
            })
            createImmunization([
                {
                    occurrenceDateTime: value.toISOString(),
                    resourceType: 'Immunization',
                    status: 'completed',
                    vaccineCode: {
                        coding: [
                            {
                                code: immunizationCode,
                                display: immunizationDisplay,
                                system: immunizationSystem
                            }
                        ],
                        text: immunizationDisplay
                    }
                }
            ])
            addedImmunization({
                isRecommended: !!isRecommended
            })
        } else {
            // EDIT
            if (new Date(immunization.occurrenceDateTime) === value) {
                // No changes
                return
            }

            console.log('Editing immunization', {
                immunization,
                value
            })
            editImmunization({
                ...immunization,
                occurrenceDateTime: value.toISOString()
            })
            editedImmunization({
                IsRecommended: !!isRecommended
            })
        }
    }

    const removeImmunization = (
        index: number,
        immunization: MedplumImmunizationItem
    ) => {
        remove(index)
        removedImmunization({
            IsRecommended: !!isRecommended
        })
        editImmunization({
            ...immunization,
            status: 'entered-in-error'
        })
    }

    const handleRemoveClick = (
        index: number,
        field: Partial<ArrayField<Record<string, any>, 'id'>>
    ) => {
        const immunization = immunizations.find(
            x => x.id === field.immunizationId
        )
        console.log('Removing immunization', {
            immunization
        })
        if (immunization) {
            removeImmunization(index, immunization)
        }
    }

    const onCheckboxChange = (
        evt: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
        onCheckboxClick && onCheckboxClick(evt)
        const checked = (evt.target as any).checked
        // Uncheck
        if (checked) {
            return
        }

        if (fields.length === 0) {
            return
        }

        const immunizationsToRemove = immunizations.filter(immunization =>
            fields.some(field => field.immunizationId === immunization.id)
        )

        for (const immunization of immunizationsToRemove) {
            const index = fields.findIndex(
                field => field.immunizationId === field.id
            )
            removeImmunization(index, immunization)
        }
    }

    useEffect(() => {
        if (isLoading || loaded) {
            return
        }

        if (defaultSelected && !isChecked) {
            setValue(name, true)
        }

        const dates = immunizations
            .filter(immunization => immunization.status === 'completed')
            .filter(
                (x: any) =>
                    x.vaccineCode.coding[0].code.toString() ===
                    immunizationCode.toString()
            )
            .map((x: any) => {
                const date = x.occurrenceDateTime
                return {
                    value: date ? date.split('T')[0] : date,
                    immunizationId: x.id,
                    lastTouchedByPatient: lastTouchedByPatient(x),
                    lastUpdatedAt: x?.meta?.lastUpdated
                }
            })

        if (dates.length > 0 && fields.length < dates.length) {
            dates.forEach((date: any) => append(date))
            setLoaded(true)
        }

        if (isChecked && dates.length === 0 && fields.length === 0) {
            append({ value: '', lastTouchedByPatient: true })
        }
    }, [immunizationCode, immunizations, isLoading, fields, isChecked, loaded])

    return (
        <>
            <Label
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    fontSize: 4,
                    cursor: 'pointer'
                }}
            >
                <Checkbox
                    pretty
                    sx={{ minWidth: 'auto' }}
                    name={name}
                    onClick={onCheckboxChange}
                />
                {label}
            </Label>
            {supportText && (
                <Label
                    sx={{
                        width: 'auto',
                        margin: 0,
                        fontSize: 0,
                        color: 'supportText',
                        marginLeft: 56
                    }}
                >
                    {supportText}
                </Label>
            )}

            {isChecked &&
                fields.map((field, i) => (
                    <Box key={field.id}>
                        <Label sx={{ mt: 4 }}>Date</Label>
                        <ThemeUI.Input
                            type="date"
                            ref={register({ required: true })}
                            name={`${datesName}.${i}.value`}
                            defaultValue={field.value}
                            disabled={
                                !field.lastTouchedByPatient && field.value
                            }
                            onBlur={evt => handleOnBlur(evt, field)}
                            placeholder={'mm/dd/yyyy'}
                            sx={{
                                'mb': 0,
                                'color': 'text',
                                '::-webkit-calendar-picker-indicator': {
                                    filter: 'invert(71%) sepia(2%) saturate(5054%) hue-rotate(319deg) brightness(92%) contrast(68%)'
                                },
                                ':disabled': {
                                    'borderColor': undefined,
                                    '::-webkit-calendar-picker-indicator': {
                                        visibility: 'visible',
                                        filter: 'invert(70%) sepia(5%) saturate(1779%) hue-rotate(314deg) brightness(91%) contrast(77%)'
                                    }
                                }
                            }}
                        />
                        {fields.length > 1 && field.lastTouchedByPatient && (
                            <Label
                                sx={{
                                    mt: 1,
                                    mb: 0,
                                    fontSize: 0,
                                    color: 'raspberry',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleRemoveClick(i, field)}
                            >
                                Remove
                            </Label>
                        )}

                        {field.lastUpdatedAt && !field.lastTouchedByPatient && (
                            <Text
                                sx={{
                                    mt: 1,
                                    fontSize: 0,
                                    fontWeight: 700,
                                    color: 'supportText'
                                }}
                            >
                                last created or updated by care team on{' '}
                                {format(
                                    new Date(field.lastUpdatedAt),
                                    'MM-dd-yyyy'
                                )}
                            </Text>
                        )}
                    </Box>
                ))}

            {isChecked && (
                <Flex
                    sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mt: 2
                    }}
                >
                    <Label
                        onClick={() => {
                            append({
                                value: '',
                                lastTouchedByPatient: true
                            })
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                            color: 'raspberry',
                            cursor: 'pointer',
                            fontSize: 0,
                            mb: 0
                        }}
                    >
                        <PlusIcon color="raspberry" sx={{ mr: 2 }} /> Add
                        another
                    </Label>
                </Flex>
            )}
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        </>
    )
}
