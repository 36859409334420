import { Button, Flex, Form, Text, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { FC, useEffect } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { LoadingScreen } from 'src/pages/Home'
import {
    convertToFormPayload as otherImmunizationsConvertToFormPayload,
    OtherImmunizations
} from './components/OtherImmunizations'
import {
    convertToFormPayload,
    RecommendedImmunizations,
    useRecommendedImmunizations
} from './components/RecommendedImmunizations'
import { useGetImmunizations } from './hooks/useGetImmunizations'
import { useSubmitImmunizationSeen } from 'src/hooks/useSubmitImmunizationsSeen'

export const Immunizations: FC = () => {
    const mutateImmunizationSeen = useSubmitImmunizationSeen()

    const { immunizations, isLoading: immunizationLoading } =
        useGetImmunizations()
    const recommendedImmunizations = useRecommendedImmunizations()

    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all'
        //resolver: yupResolver(GynecologySchema)
    })

    useEffect(() => {
        if (immunizations?.length > 0) {
            otherImmunizationsConvertToFormPayload(
                immunizations,
                recommendedImmunizations,
                formMethods
            )
            convertToFormPayload(
                immunizations,
                recommendedImmunizations,
                formMethods
            )
        }
    }, [immunizations, recommendedImmunizations])

    const onSubmit = async () => {
        await mutateImmunizationSeen.submitImmunizationSeen()
        const searchParams = new URLSearchParams(window.location.search)
        const redirectTo = searchParams.get('redirectTo')

        window.location.href = redirectTo || '/r/your-care/health-record'
    }

    return (
        <LoadingScreen
            isLoading={immunizationLoading}
            loadingComponent={<Spinner color="supportText" />}
        >
            <LayoutGrid columns="one">
                <FormProvider {...formMethods}>
                    <Form useFormMethods={formMethods} onSubmit={onSubmit}>
                        <RecommendedImmunizations />
                        <OtherImmunizations />

                        <Button
                            sx={{ mt: 5, mb: 8 }}
                            fullWidth
                            disabled={!formMethods.formState.isValid}
                        >
                            <Flex
                                sx={{
                                    width: '100%',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Text
                                    sx={{
                                        color: 'white',
                                        fontSize: 4,
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        mb: 0
                                    }}
                                >
                                    My Vaccination info is correct
                                </Text>
                                <Text
                                    sx={{
                                        color: 'white',
                                        fontSize: 0,
                                        textAlign: 'center',
                                        mb: 0
                                    }}
                                >
                                    Complete Medical History
                                </Text>
                            </Flex>
                        </Button>
                    </Form>
                </FormProvider>
            </LayoutGrid>
        </LoadingScreen>
    )
}
